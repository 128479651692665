import React from "react"
import { graphql, PageProps } from "gatsby"

import { Heading } from "@/components/heading"
import { Layout } from "@/components/layout"
import { Form } from "@/components/form"
import { ArticleCategories } from "@/components/articleCategories"
import { ArticleListHeading } from "@/components/articleListHeading"
import { ArticleList } from "@/components/articleList"

import styles from "./index.module.scss"


const News: React.VFC<PageProps<GatsbyTypes.NewsPageQuery>> = ({ data }) => {
  const newsNodes = data.allMicrocmsNews.nodes
  const categoryNodes = data.allMicrocmsCategories.nodes

  return (
    <Layout>
      <div className={styles.newsWrapper}>
        <div className={styles.news}>
          <Heading
            level="h1"
            size="Large"
            title={`ECのご担当者様に役立つ情報をお届けします。`}
            subTitle="ニュース"
          />
          <div className={styles.spacer} aria-hidden="true"></div>
          <ArticleCategories categories={categoryNodes} path="news" />
          <ArticleListHeading title="資料ダウンロード" />
          <ArticleList articles={newsNodes} path="news" />
        </div>
      </div>
      <Form />
    </Layout>
  )
}

export const query = graphql`
  query NewsPage {
    allMicrocmsNews(sort: { fields: date, order: DESC }) {
      nodes {
        newsId
        title
        date(formatString: "YYYY/MM/DD")
        img {
          url
          height
          width
        }
      }
    }
    allMicrocmsCategories {
      nodes {
        categoriesId
        name
        pathName
      }
    }
  }
`

export default News
